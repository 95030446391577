import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import { BASE_URL } from "../utils/constants";
import IMAGES from "../utils/images";
import { withRouter } from "react-router";
import { getStates, getCityByZipcode } from "../api/location";
import countriesData from "../enums/countries.json";
import sexData from "../enums/sex.json";
import MuiPhoneNumber from "material-ui-phone-number";
import InfoIcon from "@material-ui/icons/Info";
import { validateCoupon } from "../api/case";
import * as Sentry from "@sentry/react";
import enTranslations from "./../utils/language/en";
import esTranslations from "./../utils/language/es";
import { savePayment } from "../api/payment";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 20,
    paddingLeft: 60,
    paddingRight: 60
  },
  headerSection: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
    backgroundColor: "#FFEF65",
    borderRadius: 7,
  },
  subHeaderSection: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#FFEF65",
    fontWeight: "bold",
    backgroundColor: "black",
  },
  payButton: {
    color: "#FFEF65",
    backgroundColor: "black",
    height: 50,
    width: 200,
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 5,
  },
  applyButton: {
    color: "#FFEF65",
    backgroundColor: "black",
    height: 50,
    width: "100%",
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 22,
  },
  inputText: {
    width: "100%",
    height: 30,
    marginTop: 20,
    borderRadius: 10,
  },
  cardText: {
    width: "100%",
    height: 30,
    borderRadius: 10,
  },
  modal: {
    backgroundColor: "#FFEF65",
    color: "black",
    textAlign: "center",
  },
  buttonSearch: {
    color: "#FFEF65",
    backgroundColor: "black",
    height: 50,
    width: "100%",
    fontWeight: "bold",
    fontSize: 16,
  },
  caseHeader: {
    fontWeight: "bold",
    textAlign: "right",
    width: "40%",
    paddingRight: 10,
    fontSize: 14,
  },
  caseLabel: {
    textAlign: "left",
    width: "50%",
    fontSize: 14,
  },
  addressText: {
    width: "100%",
    height: 30,
    marginTop: 20,
  },
  checkbox: {
    marginTop: 10,
  },
  table: {
    minWidth: 600,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.state = {
      groupId: params.get("groupid"),
      firstName: "",
      lastName: "",
      email: "",
      paymentAmount: "",
      isSubmitting: false,
      alertMessage: "",
      openAlert: false,
      openTermsAndConditions: false,
      visibleButton: true,
      cases: [],
      caseState: "",
      caseCounty: "",
      caseNumber: "",
      caseCitationDate: "",
      backShow: false,
      client: {
        id: 0,
        firstName: "",
        lastName: "",
        middleName: "",
        driverLicense: "",
        mobileNumber: "",
        email: "",
        zipCode: "",
        countryId: "226",
        address1: "",
        address2: "",
        city: "0",
        licenseStateId: "0",
        sexId: "2",
        dateOfBirth: "",
        stateAbbr: "0",
        language: "en",
      },
      errorMap: {
        eFirstName: false,
        eLastName: false,
        eDriverLicense: false,
        eMobileNumber: false,
        eEmail: false,
        eZipCode: false,
        eCountryId: false,
        eAddress1: false,
        eCity: false,
        eLicenseStateId: false,
        eSexId: false,
        eDateOfBirth: false,
        eStateAbbr: false,
        eCCNumber: false,
        eCCExp: false,
        eCCCvv: false
      },
      sexs: sexData.sex,
      countries: countriesData.countries,
      prices: [],
      states: [],
      cities: [],
      filterTickets: [],
      barcodeId: [],
      errorCCNumber: "",
      errorCCExp: "",
      errorCCVV: "",
      disableButton: false,
      paymentExp: "",
      ticketList: "",
      caseNumberList: "",
      frameUid: "",
      cardType: "",
      paymentToken: "",
      userName: "",
      countyName: "",
      coupon: "",
      couponId: 0,
      couponAmount: 0.0,
      couponPercentage: 0.0,
      isCouponPercentage: false,
      isCouponDisabled: false,
      ccNumber: "",
      ccExp: "",
      ccCvv: "",
    };
    this.setState = this.setState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.finishSubmit = this.finishSubmit.bind(this);
    this.filterTicketList = this.filterTicketList.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseTermsAndConditions =
      this.handleCloseTermsAndConditions.bind(this);
    this.openTerms = this.openTerms.bind(this);
    this.getCityByZipcodeDropdow = this.getCityByZipcodeDropdow.bind(this);
    this.getStatesDropdown = this.getStatesDropdown.bind(this);
    this.validateCreditCardData = this.validateCreditCardData.bind(this);
  }

  componentDidMount() {
    this.getStatesDropdown();

    fetch(BASE_URL + "/case/GetPayInfoByQuoteGroupId/" + this.state.groupId, {
      method: "GET",
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        Sentry.captureMessage(
          "Method:GetPayInfoByQuoteGroupId|Response " + JSON.stringify(data)
        );
        var citationDateFormat;
        let newPrices = [];
        let newCases = [];
        let barcodes = [];
        if (data.caseList.length > 0) {
          data.caseList.forEach((caseItem) => {
            if (caseItem.caseTickets.length > 0) {
              caseItem.caseTickets.forEach((element) => {
                let price = {};
                price.barcodeId = caseItem.barcodeId;
                price.ticketNumber = element.ticketNumber;
                price.chargeAmount = parseFloat(element.chargeAmount).toFixed(
                  2
                );
                price.infraction = element.infraction;
                newPrices.push(price);
              });
            }
            var date = new Date(caseItem.citationDate);
            citationDateFormat =
              date.getDate() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getFullYear();
            let newCase = {};
            newCase.barcodeId = caseItem.barcodeId;
            newCase.citationDate = citationDateFormat;
            newCase.caseNumber = caseItem.caseNumber;
            newCase.state = caseItem.state;
            newCase.county = caseItem.county;
            newCase.price = caseItem.payAmount.toFixed(2);
            newCase.isAlreadyPaid = caseItem.isAlreadyPaid;
            newCases.push(newCase);
          });
        }

        if (newCases.length > 0) {
          newCases.forEach((cItem) => {
            if (cItem.isAlreadyPaid != false) {
              this.setState({
                openAlert: true,
                alertMessage:
                  this.state.client.language === "en"
                    ? enTranslations.handleCloseMessage
                    : esTranslations.handleCloseMessage,
                visibleButton: true,
                paymentExp: "PAI",
              });
            }
            barcodes.push(String(cItem.barcodeId));
          });
        }

        this.setState({
          firstName: data.client.firstName,
          lastName: data.client.lastName,
          email: data.client.email,
          cases: newCases,
          paymentAmount: parseFloat(data.payAmount).toFixed(2),
          originalPaymentAmount: parseFloat(data.payAmount).toFixed(2),
          client: {
            id: data.client.id,
            firstName: data.client.firstName,
            lastName: data.client.lastName,
            middleName: data.client.middleName,
            driverLicense: data.client.driverLicense,
            mobileNumber: data.client.mobileNumber,
            email: data.client.email,
            zipCode: data.client.zipCode,
            isActive: 1,
            countryId: data.client.country,
            address1: data.client.address1,
            address2: data.client.address2,
            city: data.client.city,
            licenseStateId: data.client.stateAbbr,
            stateAbbr: data.client.clientStateAbbr,
            sexId: data.client.sex,
            dateOfBirth: data.client.dateOfBirth,
            language: data.client.language,
          },
          prices: newPrices,
          ticketList: data.ticketList,
          barcodeId: barcodes,
          caseNumberList: data.caseNumberList,
          userName: data.caseList[0].userName,
          countyName: data.caseList[0].county,
        });

        this.getCityByZipcodeDropdow(data.client.zipCode);

        if (data.paymentExpired === "YES") {
          this.setState({
            openAlert: true,
            alertMessage:
              this.state.client.language === "en"
                ? enTranslations.componentDidMountMessageOne
                : esTranslations.componentDidMountMessageOne,
            visibleButton: false,
            paymentExp: data.paymentExpired,
          });
        }
      })
      .catch((error) => {
        console.error("error", error);
        this.setState({
          openAlert: true,
          alertMessage:
            this.state.client.language === "en"
              ? enTranslations.componentDidMountMessageTwo
              : esTranslations.componentDidMountMessageTwo,
          visibleButton: false,
          paymentExp: "YES",
        });
      });
  }

  async getCityByZipcodeDropdow(zipcode) {
    try {
      const response = await getCityByZipcode(zipcode);
      if (typeof response != "undefined") {
        this.setState({ cities: response });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getStatesDropdown() {
    try {
      const response = await getStates();
      if (typeof response != "undefined") {
        this.setState({ states: response });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async savePaymentCallback(formData) {
    try {
      this.setState({ backShow: true, disableButton: true });
      Sentry.captureMessage(
        "Method:AddPaymentChase|Request " + JSON.stringify(formData)
      );
      const response = await savePayment(formData);
      Sentry.captureMessage(
        "Method:AddPaymentChase|Response " + JSON.stringify(response)
      );
      if (typeof response != "undefined") {
        if (response != "Success") {
          this.setState({
            openAlert: true,
            alertMessage: response,
            visibleButton: true,
            backShow: false,
            paymentExp: "PAI",
          });
        } else {
          this.setState({
            openAlert: true,
            alertMessage:
              this.state.client.language === "en"
                ? enTranslations.savePaymentCallbackMessageOne
                : esTranslations.savePaymentCallbackMessageOne,
            backShow: false,
            visibleButton: false,
            paymentExp: "NO",
            disableButton: false
          });
        }
      } else {
        this.setState({
          openAlert: true,
          alertMessage:
            this.state.client.language === "en"
              ? enTranslations.savePaymentCallbackMessageTwo
              : esTranslations.savePaymentCallbackMessageTwo,
          backShow: false,
          disableButton: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  filterTicketList(barcodeId) {
    this.state.filterTickets.length = 0;
    let newFilter = this.state.filterTickets;
    for (let i = 0; i < this.state.prices.length; i++) {
      if (this.state.prices[i].barcodeId === barcodeId) {
        newFilter.push(this.state.prices[i]);
      }
    }
    this.setState({ filterTickets: newFilter });
  }

  async validateCouponValue() {
    try {
      this.setState({ backShow: true });
      const response = await validateCoupon(this.state.coupon);
      if (this.state.coupon !== "") {
        if (response.couponId !== 0) {
          this.setState({
            couponId: response.couponId,
            couponAmount: response.amount,
            couponPercentage: response.percentage,
            isCouponPercentage: response.couponType === "Amount" ? false : true,
          });

          switch (response.couponType) {
            case "Amount":
              this.setState({
                paymentAmount:
                  (this.state.originalPaymentAmount - response.amount).toFixed(2),
                backShow: false,
              });
              break;
            case "Percentage":
              var discount =
                (response.percentage / 100) * this.state.originalPaymentAmount;
              this.setState({
                paymentAmount: (
                  this.state.originalPaymentAmount - discount
                ).toFixed(2),
                backShow: false,
              });
              break;
            default:
              break;
          }
        } else {
          this.setState({
            openAlert: true,
            alertMessage:
              this.state.client.language === "en"
                ? enTranslations.validateCouponValueMessageOne
                : esTranslations.validateCouponValueMessageOne,
            backShow: false,
            paymentAmount: this.state.originalPaymentAmount,
            coupon: "",
          });
        }
      } else {
        this.setState({
          openAlert: true,
          alertMessage:
            this.state.client.language === "en"
              ? enTranslations.validateCouponValueMessageTwo
              : esTranslations.validateCouponValueMessageTwo,
          backShow: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  finishSubmit() {
    const {
      isSubmitting,
      alertMessage,
      openAlert,
      openTermsAndConditions,
      visibleButton,
      paymentAmount,
      clientFirstName,
      clientLastName,
      clientMobileNumber,
      clientEmail,
      cases,
      sexs,
      states,
      cities,
      countries,
      prices,
      backShow,
      errorMap,
      ticketList,
      caseNumberList,
      errorTerms,
      errorCCNumber,
      errorCCExp,
      filterTickets,
      errorCCVV,
      disableButton,
      paymentExp,
      ...formData
    } = { ...this.state };
    formData.id = this.state.groupId;
    formData.quoteGroupId = this.state.groupId;
    formData.client.licenseStateId = parseInt(this.state.client.licenseStateId);
    formData.client.sexId = parseInt(this.state.client.sexId);
    formData.client.countryId = String(this.state.client.countryId);
    formData.paymentToken = this.state.paymentToken;
    formData.paymentAmount = parseFloat(this.state.paymentAmount);
    formData.couponAmount = parseFloat(this.state.couponAmount);
    formData.couponPercentage = parseFloat(this.state.couponPercentage);
    formData.ccNumber = this.state.ccNumber.replace(/-/g, '');
    this.savePaymentCallback(formData);
  }

  validateCreditCardData() {
    var eCardNumber = false;
    var eCardExp = false;
    var eCardCvv = false;
    if (
      this.state.ccNumber === "" ||
      this.state.ccNumber === null ||
      !this.validateCardNumber()
    ) {
      eCardNumber = true;
    }
    if (
      this.state.ccExp === "" ||
      this.state.ccExp === null ||
      !this.validateExpiryDate()
    ) {
      eCardExp = true;
    }
    if (
      this.state.ccCvv === "" ||
      this.state.ccCvv === null ||
      !this.validateCvv()
    ) {
      eCardCvv = true;
    }
    this.setState({
      errorMap: {
        ...this.state.errorMap,
        eCCNumber: eCardNumber,
        eCCExp: eCardExp,
        eCCCvv: eCardCvv,
      },
    });
    if (eCardNumber || eCardExp || eCardCvv) {
      return false;
    } else {
      return true;
    }
  }

  validateFormFields() {
    if (
      this.state.client.firstName === "" ||
      this.state.client.firstName === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eFirstName: true } });
    }
    if (
      this.state.client.lastName === "" ||
      this.state.client.lastName === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eLastName: true } });
    }
    if (
      this.state.client.driverLicense === "" ||
      this.state.client.driverLicense === null
    ) {
      this.setState({
        errorMap: { ...this.state.errorMap, eDriverLicense: true },
      });
    }
    if (
      this.state.client.mobileNumber.length > 5 ||
      this.state.client.mobileNumber === null
    ) {
      this.setState({
        errorMap: { ...this.state.errorMap, eMobileNumber: true },
      });
    }
    if (this.state.client.email === "" || this.state.client.email === null) {
      this.setState({ errorMap: { ...this.state.errorMap, eEmail: true } });
    }
    if (
      this.state.client.zipcode === "" ||
      this.state.client.zipcode === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eZipCode: true } });
    }
    if (
      this.state.client.countryId === "0" ||
      this.state.client.countryId === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eCountryId: true } });
    }
    if (
      this.state.client.address1 === "" ||
      this.state.client.address1 === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eAddress1: true } });
    }
    if (this.state.client.city === "0" || this.state.client.city === null) {
      this.setState({ errorMap: { ...this.state.errorMap, eCity: true } });
    }
    if (
      this.state.client.licenseStateId === "0" ||
      this.state.client.licenseStateId === null
    ) {
      this.setState({
        errorMap: { ...this.state.errorMap, eLicenseStateId: true },
      });
    }
    if (this.state.client.sexId === "0" || this.state.client.sexId === null) {
      this.setState({ errorMap: { ...this.state.errorMap, eSexId: true } });
    }
    if (
      this.state.client.dateOfBirth === "" ||
      this.state.client.dateOfBirth === null
    ) {
      this.setState({
        errorMap: { ...this.state.errorMap, eDateOfBirth: true },
      });
    }
    if (
      this.state.client.stateAbbr === "0" ||
      this.state.client.stateAbbr === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eStateAbbr: true } });
    }
    if (Object.values(this.state.errorMap).includes(true)) {
      this.setState({
        openAlert: true,
        alertMessage:
          this.state.client.language === "en"
            ? enTranslations.validateFormFieldsMessage
            : esTranslations.validateFormFieldsMessage,
        visibleButton: true,
      });
      return false;
    } else {
      return true;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.client.mobileNumber.length > 5) {
      this.setState({
        errorMap: { ...this.state.errorMap, eMobileNumber: false },
        isSubmitting: true,
      });
      if (this.validateCreditCardData()) {
        this.finishSubmit();
      }
    } else {
      this.setState({
        errorMap: { ...this.state.errorMap, eMobileNumber: true },
      });
      this.setState({
        openAlert: true,
        alertMessage:
          this.state.client.language === "en"
            ? enTranslations.handleSubmitMessage
            : esTranslations.handleSubmitMessage,
        visibleButton: true,
      });
      return false;
    }
  }

  handleClose() {
    this.setState({ openAlert: false });
    if (
      this.state.visibleButton === false ||
      (this.state.alertMessage === this.state.client.language) === "en"
        ? enTranslations.handleCloseMessage
        : esTranslations.handleCloseMessage
    ) {
      this.props.history.push(
        "/payment-success?exp=" +
          this.state.paymentExp +
          "&emi=" +
          this.state.client.email
      );
    }
  }

  handleCloseTermsAndConditions() {
    this.setState({ openTermsAndConditions: false });
  }

  openTerms() {
    this.setState({ openTermsAndConditions: true });
  }

  validateCardNumber() {
    return /^\d{4}-\d{4}-\d{4}-\d{4}$/.test(this.state.ccNumber);
  }

  validateExpiryDate() {
    return /^(0[1-9]|1[0-2])\/\d{2}$/.test(this.state.ccExp);
  }

  validateCvv() {
    return /^\d{3,4}$/.test(this.state.ccCvv);
  }

  render() {
    const currentTranslations =
      this.state.client.language === "en" ? enTranslations : esTranslations;
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.subHeaderSection}>
          <table style={{ width: "100%", textAlign: "center" }}>
            <tr>
              <td style={{ textAlign: "center", width: "30%" }}>
                <img alt="path" src={IMAGES.TTCLogo} width={70} height={70} />
              </td>
              <td style={{ textAlign: "left", width: "70%" }}>
                {currentTranslations.headerCase} /{" "}
                {currentTranslations.headerCitation}:{" "}
                {this.state.caseNumberList} / {this.state.ticketList}
              </td>
            </tr>
          </table>
        </div>
        <div className={classes.root}>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.headerSection}>
                  {currentTranslations.headerClient}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <b>
                  <i>{currentTranslations.note}</i>
                </b>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  disabled="true"
                  label={currentTranslations.firstName}
                  className={classes.inputText}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eFirstName}
                  value={this.state.client.firstName}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        firstName: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  disabled="true"
                  label={currentTranslations.lastName}
                  className={classes.inputText}
                  variant="outlined"
                  required
                  value={this.state.client.lastName}
                  error={this.state.errorMap.eLastName}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        lastName: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  disabled="true"
                  label={currentTranslations.middleName}
                  className={classes.inputText}
                  variant="outlined"
                  value={this.state.client.middleName}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        middleName: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  label={currentTranslations.email}
                  className={classes.inputText}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eEmail}
                  value={this.state.client.email}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        email: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label={currentTranslations.address1}
                  className={classes.addressText}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eAddress1}
                  value={this.state.client.address1}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        address1: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label={currentTranslations.address2}
                  className={classes.addressText}
                  variant="outlined"
                  value={this.state.client.address2}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        address2: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  label={currentTranslations.zipcode}
                  className={classes.inputText}
                  variant="outlined"
                  required
                  value={this.state.client.zipCode}
                  error={this.state.errorMap.eZipCode}
                  onChange={(event) => {
                    this.setState({
                      client: {
                        ...this.state.client,
                        zipCode: event.target.value,
                      },
                    });
                    this.getCityByZipcodeDropdow(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label={currentTranslations.city}
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  required
                  value={this.state.client.city}
                  error={this.state.errorMap.eCity}
                  className={classes.inputText}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        city: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" disabled value="0">
                    {currentTranslations.selectDefault}
                  </option>
                  {this.state.cities.map((option) => (
                    <option key={option.cityName} value={option.cityName}>
                      {option.cityName}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label={currentTranslations.country}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  className={classes.inputText}
                  value={this.state.client.countryId}
                  required
                  error={this.state.errorMap.eCountryId}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        countryId: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" disabled value="0">
                    {currentTranslations.selectDefault}
                  </option>
                  {this.state.countries.map((option) => (
                    <option key={option.countryId} value={option.countryId}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label={currentTranslations.state}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  className={classes.inputText}
                  required
                  error={this.state.errorMap.eStateAbbr}
                  value={this.state.client.stateAbbr}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        stateAbbr: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" value="0" disabled>
                    {currentTranslations.selectDefault}
                  </option>
                  {this.state.states.map((option) => (
                    <option key={option.id} value={option.stateAbbr}>
                      {option.stateName}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <MuiPhoneNumber
                  defaultCountry={"us"}
                  value={this.state.client.mobileNumber}
                  variant="outlined"
                  label={currentTranslations.mobileNumber}
                  required={this.state.errorMap.eMobileNumber}
                  error={this.state.errorMap.eMobileNumber}
                  className={classes.inputText}
                  onChange={(event) =>
                    this.setState({
                      client: { ...this.state.client, mobileNumber: event },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label={currentTranslations.sex}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  value={this.state.client.sexId}
                  required
                  error={this.state.errorMap.eSexId}
                  className={classes.inputText}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        sexId: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" value="0" disabled>
                    {currentTranslations.selectDefault}
                  </option>
                  {this.state.sexs.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  id="date"
                  label={currentTranslations.dateOfBirth}
                  type="date"
                  variant="outlined"
                  className={classes.inputText}
                  required
                  error={this.state.errorMap.eDateOfBirth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.client.dateOfBirth}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        dateOfBirth: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  label={currentTranslations.driverLicense}
                  className={classes.inputText}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eDriverLicense}
                  value={this.state.client.driverLicense}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        driverLicense: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label={currentTranslations.stateIssued}
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eLicenseStateId}
                  value={this.state.client.licenseStateId}
                  className={classes.inputText}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        licenseStateId: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" disabled value="0">
                    {" "}
                    {currentTranslations.selectDefault}
                  </option>
                  {this.state.states.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.stateAbbr}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <div style={{ paddingTop: 25 }} />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.headerSection}>
                  {currentTranslations.headerCaseList}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {" "}
                          {currentTranslations.tableState}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {" "}
                          {currentTranslations.tableCounty}
                        </TableCell>
                        <TableCell
                          algin="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {" "}
                          {currentTranslations.tableCaseNumber}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {" "}
                          {currentTranslations.tableCitationDate}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {" "}
                          {currentTranslations.tablePrice}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {" "}
                          {currentTranslations.tableTickets}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.cases.map((row) => (
                        <TableRow key={row.caseNumber}>
                          <TableCell component="th" align="center" scope="row">
                            {row.state}
                          </TableCell>
                          <TableCell align="center">{row.county}</TableCell>
                          <TableCell align="center">{row.caseNumber}</TableCell>
                          <TableCell align="center">
                            {row.citationDate}
                          </TableCell>
                          <TableCell align="center">$ {row.price}</TableCell>
                          <TableCell align="center">
                            <label
                              onClick={() =>
                                this.filterTicketList(row.barcodeId)
                              }
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              {currentTranslations.tableView}
                            </label>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.headerSection}>
                  {currentTranslations.headerCaseTickets}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{ fontWeight: "bold" }}>
                          {" "}
                          {currentTranslations.tableCitationNumber}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {" "}
                          {currentTranslations.tableDescription}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>
                          {currentTranslations.tablePrice2}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.filterTickets.map((row) => (
                        <TableRow key={row.ticketNumber}>
                          <TableCell component="th" scope="row">
                            {row.ticketNumber}
                          </TableCell>
                          <TableCell align="center">{row.infraction}</TableCell>
                          <TableCell align="right">
                            $ {row.chargeAmount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.headerSection}>
                  {currentTranslations.headerPaymentInformation}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img
                    src={IMAGES.CreditCards}
                    alt="path"
                    width="100%"
                    height="100%"
                  ></img>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label={currentTranslations.paymentFirstName}
                  className={classes.inputText}
                  variant="outlined"
                  onChange={(event) =>
                    this.setState({ firstName: event.target.value })
                  }
                  value={this.state.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label={currentTranslations.paymentLastName}
                  className={classes.inputText}
                  variant="outlined"
                  onChange={(event) =>
                    this.setState({ lastName: event.target.value })
                  }
                  value={this.state.lastName}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <TextField
                  label={currentTranslations.paymentAmount}
                  className={classes.inputText}
                  variant="outlined"
                  disabled
                  onChange={(event) =>
                    this.setState({ paymentAmount: event.target.value })
                  }
                  value={this.state.paymentAmount}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <TextField
                  label={currentTranslations.paymentCoupon}
                  className={classes.inputText}
                  variant="outlined"
                  onChange={(event) =>
                    this.setState({ coupon: event.target.value })
                  }
                  value={this.state.coupon}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Button
                  className={classes.applyButton}
                  disabled={this.state.isCouponDisabled}
                  onClick={() => this.validateCouponValue()}
                >
                  {currentTranslations.paymentApply}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label={currentTranslations.cardNumberPlaceholder}
                  variant="outlined"
                  className={classes.inputText}
                  value={this.state.ccNumber}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    const formattedValue = value
                      .replace(/(.{4})/g, "$1-")
                      .slice(0, 19);
                    this.setState({ ccNumber: formattedValue });
                  }}
                  error={this.state.errorMap.eCCNumber}
                  helperText={
                    this.state.errorMap.eCCNumber ? currentTranslations.validateCardNumber : ""
                  }
                  style={{ marginBottom: 15 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label={currentTranslations.cardExperyDatePlaceholder}
                  variant="outlined"
                  className={classes.inputText}
                  value={this.state.ccExp}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    const formattedValue = value
                      .replace(/(.{2})/, "$1/")
                      .slice(0, 5);
                    this.setState({ ccExp: formattedValue });
                  }}
                  error={this.state.errorMap.eCCExp}
                  helperText={
                    this.state.errorMap.eCCExp? currentTranslations.validateCardExpiryDate : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label="CVV"
                  variant="outlined"
                  className={classes.inputText}
                  value={this.state.ccCvv}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                    this.setState({ ccCvv: value });
                  }}
                  error={this.state.errorMap.eCCCvv}
                  helperText={this.state.errorMap.eCCCvv ? currentTranslations.validateCardCvv : ""}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.checkbox}
                style={{ textAlign: "center", marginTop: 30 }}
              >
                <label>
                  <i>
                    {currentTranslations.termsAndConditionsInformation}{" "}
                    <b>{currentTranslations.termsAndConditions}</b>
                  </i>
                </label>
                &nbsp;
                <InfoIcon onClick={this.openTerms} />
              </Grid>
              {this.state.visibleButton && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={this.state.disableButton}
                    className={classes.payButton}
                  >
                    {currentTranslations.payButton}
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
          <Dialog
            open={this.state.openAlert}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"xs"}
          >
            <DialogTitle id="responsive-dialog-title" className={classes.modal}>
              {this.state.alertMessage}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                className={classes.buttonSearch}
                autoFocus
              >
                {currentTranslations.closeButton}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openTermsAndConditions}
            onClose={this.handleCloseTermsAndConditions}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"md"}
          >
            <DialogTitle
              id="responsive-dialog-title"
              className={classes.modal}
              style={{ fontWeight: "bold" }}
            >
              {currentTranslations.terms} &#38; {currentTranslations.conditions}
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "black" }}>
                <p style={{ textAlign: "justify" }}>
                  {currentTranslations.firstTermsAndConditions}
                </p>
                <ol>
                  <li value="1" style={{ textAlign: "justify" }}>
                    {currentTranslations.secondTermsAndConditions}
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {currentTranslations.thirdTermsAndConditions}
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {currentTranslations.fourthTermsAndConditions}
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {currentTranslations.fifthTermsAndConditions}
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {currentTranslations.sixthTermsAndConditions}
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {currentTranslations.seventhTermsAndConditionsPt1}
                    {currentTranslations.seventhTermsAndConditionsPt2}
                    {currentTranslations.seventhTermsAndConditionsPt3}
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {currentTranslations.eightTermsAndConditions}
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {currentTranslations.ninethTermsAndConditions}
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {currentTranslations.tenthTermsAndConditions}
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {currentTranslations.eleventhTermsAndConditions}
                  </li>
                </ol>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseTermsAndConditions}
                className={classes.buttonSearch}
                autoFocus
              >
                {currentTranslations.closeButton}
              </Button>
            </DialogActions>
          </Dialog>
          <Backdrop className={classes.backdrop} open={this.state.backShow}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(PaymentPage));
