import axios from "../utils/axios";

export const getInfractionByCountyAndType = async (countyId, typeId) => {
  try {
    const response = await axios.get(
      `/combo/GetInfraction/${countyId}/${typeId}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
