const BASE_URL = process.env.REACT_APP_TTC_API_BASE_URL;
const PAYMENTS_URL = process.env.REACT_APP_TTC_API_EXTERNAL_PAYMENTS;
const PAYMENTS_KEY_SECRET = process.env.REACT_APP_TTC_API_EXTERNAL_PAYMENTS_TOKEN;
const PAYMENTS_IFRAME_UID_URL = process.env.REACT_APP_TTC_PAYMENT_IFRAME_UID_API;
const PAYMENT_BASE_URL = process.env.REACT_APP_TTC_API_BASE_URL_PAYMENT;

const ROUTES = {
    CASE_EDITOR_ROUTE: '/caseadd',
    PAYMENT_ROUTE: '/casepayment',
    PAYMENT_PARAM_ROUTE: '/casepayment/',
    PAYMENT_LANDING: '/payment-success',
    CASE_LIST: '/caselist',
    NEW_QUOTE_LIST: '/new-quote-list',
    NEW_QUOTE: '/new-quote',
    UNAUTHORIZED: '/unauthorized',
}
export { BASE_URL, ROUTES, PAYMENTS_URL, PAYMENTS_KEY_SECRET, PAYMENTS_IFRAME_UID_URL, PAYMENT_BASE_URL };