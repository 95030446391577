import axios from "../utils/axios";
import * as Sentry from "@sentry/react";
import { PAYMENTS_URL, PAYMENTS_KEY_SECRET } from "../utils/constants";

export const getClientByDriverLicense = async (driverLicense) => {
  try {
    const response = await axios.get(`/client/GetClient/${driverLicense}`, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getClientByDriverLicenseAndDob = async (driverLicense, dob) => {
  try {
    const response = await axios.get(`/client/GetClientByDob/${driverLicense}/${dob}`, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCasesByClient = async (clientId) => {
  try {
    const response = await axios.get(`/client/GetClientCases/${clientId}`, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getClientCasesByParam = async (param) => {
  try {
    const response = await axios.get(`/client/GetClientCasesByParam/${param}`, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// export const getPaymentsCasesList = async (startDate, endDate) => {
//   try {
//     const response = await fetch(
//       PAYMENTS_URL +
//         `?endDate=${endDate}&startDate=${startDate}&keySecret=${PAYMENTS_KEY_SECRET}`
//     ).then((res) => res.json());
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getPaymentsCasesList = async (startDate, endDate) => {
  try {
    const response = await axios.get(
      `/WebSale/GetWebSaleCases/${startDate}/${endDate}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getProcessedPayments = async (startDate, endDate) => {
  try {
    const response = await axios.get(
      `/WebSale/GetPaymentExternalCases/${startDate}/${endDate}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const validProcessedCase = async (master_id, detail_id) => {
  try {
    const response = await axios.get(
      `/WebSale/IsProcessed/${master_id}/${detail_id}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const validProcessedAndLockedCase = async (master_id, detail_id) => {
  try {
    const response = await axios.get(
      `/WebSale/IsProcessedAndLocked/${master_id}/${detail_id}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addPaymentsProcessed = async (payload) => {
  try {
    const response = await axios.post(
      `/WebSale/AddExternalPayment`,
      payload,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    Sentry.captureMessage(
      "Method:AddExternalPayment|Response " + JSON.stringify(response.data)
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
