import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { getCasesByClient, getClientCasesByParam } from "../api/client";
import { getRegions } from "../api/location";
import { deleteCase } from "../api/case";
import { validateOtp, generateOtp } from "../api/otp";
import { withRouter } from "react-router";
import DeleteIcon from "@material-ui/icons/Delete";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    width: 200,
  },
  root: {
    flexGrow: 1,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  button: {
    color: "#FFEF65",
    backgroundColor: "black",
    height: 50,
    width: "50%",
    fontWeight: "bold",
    fontSize: 16,
  },
  inputText: {
    width: "100%",
    height: 30,
    marginTop: 20,
  },
});

class CaseListPage extends React.Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.state = {
      userid: params.get("userid"),
      token: params.get("otptoken"),
      cases: [],
      searchCases: [],
      open: false,
      message: "",
      searchCase: "",
      openAlertAddCase: false,
      barcodeIdToDelete: "",
      regionSelected: "All",
      regions: [],
      showTable: true,
      backShow: false,
      showPage: false,
    };
    this.setState = this.setState.bind(this);
    this.handleAddCase = this.handleAddCase.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.deleteCaseClient = this.deleteCaseClient.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleRegionFilter = this.handleRegionFilter.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleSearchCases = this.handleSearchCases.bind(this);
  }

  componentDidMount() {
    this.validateOtp();
  }

  async validateOtp() {
    const response = await validateOtp({
      userName: this.state.userid,
      token: this.state.token,
    });
    if (typeof response == "undefined") {
      this.props.history.push("/unauthorized");
    } else {
      this.setState({ showPage: true });
      this.getListCases(this.state.userid);
      this.getRegionsList();
    }
  }

  async handleAddCase() {
    var newToken = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    await generateOtp({
      userName: this.state.userid,
      token: newToken,
    });
    this.props.history.push("/caseadd?userid=" + this.state.userid + "&otptoken=" + newToken);
  }

  handleCloseDialog() {
    this.setState({ openAlertAddCase: false });
  }

  caseDetailPage(barcodeId) {
    window.open(
      process.env.REACT_APP_TTC_TICKET_EXTERNAL_URL + barcodeId,
      "_blank"
    );
  }

  copyPaymentUrl(url, barcode, quoteGroupId, isPaid) {
    if (isPaid != "YES") {
      var newUrl = process.env.REACT_APP_TTC_PAYMENT_PAGE_URL + quoteGroupId;
      navigator.clipboard.writeText(newUrl);
      this.setState({ open: true, message: "Copied Payment URL: " + barcode });
    } else {
      this.setState({ open: true, message: "This case is already paid" });
    }
  }

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  }

  async getRegionsList() {
    try {
      const response = await getRegions();
      if (typeof response != "undefined") this.setState({ regions: response });
    } catch (error) {
      console.log(error);
    }
  }

  async getListCases(clientId) {
    try {
      const response = await getCasesByClient(clientId);
      if (typeof response != "undefined") {
        this.setState({ cases: response, searchCases: response });
        const newData = this.state.cases.map((item) => ({
          ...item,
          label: "",
        }));
        this.setState({ cases: newData, searchCases: newData });
        const rows = [...this.state.cases];
        let rowsTemp = [];
        var groupId = "";
        rows.forEach((rowTemp) => {
          if (rowTemp.quoteGroupId != groupId) {
            rowTemp.label = "Copy Link!";
            if (rowTemp.isNewQuotePaidFlag != "NO") {
              document.getElementById(rowTemp.barcodeId).style.color = "black";
              document.getElementById(rowTemp.barcodeId).style.textDecoration =
                "none";
              document.getElementById(rowTemp.barcodeId).style.cursor =
                "default";
              document.getElementById(rowTemp.barcodeId).style.pointerEvents =
                "none";
            }
            groupId = rowTemp.quoteGroupId;
          }
          rowsTemp.push(rowTemp);
        });
        this.setState({ cases: rowsTemp, searchCases: rowsTemp });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleSearchCases(param) {
    try {
      this.setState({ backShow: true });
      const response = await getClientCasesByParam(param);
      console.log(`Response: ${JSON.stringify(response.length)}`);
      if (typeof response != "undefined") {
        if (response.length === 0) this.setState({ showTable: false });
        else this.setState({ showTable: true });
        this.setState({ cases: response, searchCases: response });
        const newData = this.state.cases.map((item) => ({
          ...item,
          label: "",
        }));
        this.setState({ cases: newData, searchCases: newData });
        const rows = [...this.state.cases];
        let rowsTemp = [];
        var groupId = "";
        rows.forEach((rowTemp) => {
          if (rowTemp.quoteGroupId != groupId) {
            rowTemp.label = "Copy Link!";
            if (rowTemp.isNewQuotePaidFlag != "NO") {
              document.getElementById(rowTemp.barcodeId).style.color = "black";
              document.getElementById(rowTemp.barcodeId).style.textDecoration =
                "none";
              document.getElementById(rowTemp.barcodeId).style.cursor =
                "default";
              document.getElementById(rowTemp.barcodeId).style.pointerEvents =
                "none";
            }
            groupId = rowTemp.quoteGroupId;
          }
          rowsTemp.push(rowTemp);
        });
        this.setState({
          cases: rowsTemp,
          searchCases: rowsTemp,
          backShow: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async deleteCaseClient(barcodeId) {
    try {
      const response = await deleteCase(barcodeId);
      if (typeof response != "undefined") {
        var array = this.state.cases;
        var index = array.findIndex((x) => x.barcodeId === barcodeId);
        array.splice(index, 1);
        this.setState({
          people: array,
          barcodeIdToDelete: "",
          openAlertAddCase: false,
          open: true,
          message: "Successfully deleted",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleSearch(event) {
    this.setState({ searchCase: event.target.value, regionSelected: "All" });
    if (event.target.value === "") {
      this.getListCases(this.state.userid);
      this.setState({ showTable: true });
    }
  }

  handleRegionFilter(event) {
    var filters = event.target.value;
    var filtered = this.state.searchCases.filter(function (el) {
      if (!String(el.region).includes(filters) && filters !== "All") {
        return false;
      }
      return true;
    });
    this.setState({ cases: filtered });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.showPage ? (
          <div
            style={{
              flexGrow: 1,
              paddingTop: 20,
              paddingLeft: 50,
              paddingRight: 50,
              paddingBottom: 50,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  style={{
                    marginTop: 20,
                    color: "#FFEF65",
                    height: 55,
                    backgroundColor: "black",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                  onClick={this.handleAddCase}
                >
                  Add New Case
                </Button>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  select
                  label="Region"
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    width: "100%",
                    marginTop: 20,
                  }}
                  variant="outlined"
                  value={this.state.regionSelected}
                  onChange={(e) => {
                    this.setState({ regionSelected: e.target.value });
                    this.handleRegionFilter(e);
                  }}
                >
                  <option key="0" value="All">
                    All
                  </option>
                  {this.state.regions.map((option) => (
                    <option key={option.id} value={option.region}>
                      {option.region}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="BarcodeId / Name / Phone Number"
                  style={{
                    width: "100%",
                    marginTop: 20,
                  }}
                  variant="outlined"
                  value={this.state.searchCase}
                  onChange={this.handleSearch}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && e.target.value !== "") {
                      this.handleSearchCases(e.target.value);
                    }
                  }}
                />
              </Grid>
              {this.state.showTable ? (
                <Grid item xs={12}>
                  <TableContainer>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            Client
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            Mobile Number
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            Barcode
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            Citation Date
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            Type
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            County
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            Is Paid?
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            Created Date and Time
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            Amount
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            Payment URL
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          ></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.cases.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              {row.client}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.mobileNumber}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <label
                                onClick={() =>
                                  this.caseDetailPage(row.barcodeId)
                                }
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                {row.barcodeId}
                              </label>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.citationDate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.type}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.county}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.isNewQuotePaidFlag}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.createdDate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              $ {parseFloat(row.payAmount).toFixed(2)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <label
                                onClick={() =>
                                  this.copyPaymentUrl(
                                    row.paymentUrl,
                                    row.barcodeId,
                                    row.quoteGroupId,
                                    row.isNewQuotePaidFlag
                                  )
                                }
                                id={row.barcodeId}
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                {row.label}
                              </label>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <DeleteIcon
                                onClick={() =>
                                  this.setState({
                                    openAlertAddCase: true,
                                    barcodeIdToDelete: row.barcodeId,
                                  })
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ) : (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <h1>There is no match with the search filter</h1>
                </Grid>
              )}
            </Grid>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.open}
              autoHideDuration={6000}
              onClose={this.handleClose}
              message={this.state.message}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
            <Dialog
              open={this.state.openAlertAddCase}
              onClose={this.handleCloseDialog}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle
                id="dialog-title"
                style={{
                  backgroundColor: "#FFEF65",
                  color: "black",
                  textAlign: "center",
                }}
              >
                Delete Case
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Do you want to delete this case?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() =>
                    this.deleteCaseClient(this.state.barcodeIdToDelete)
                  }
                  style={{
                    color: "#FFEF65",
                    backgroundColor: "black",
                    height: 50,
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                  autoFocus
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      openAlertAddCase: false,
                      barcodeIdToDelete: "",
                    });
                  }}
                  style={{
                    color: "#FFEF65",
                    backgroundColor: "black",
                    height: 50,
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                  autoFocus
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
            <Backdrop
              style={{ position: "absolute", zIndex: 1 }}
              open={this.state.backShow}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <h1>Loading...</h1>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(CaseListPage));
