import axios from "../utils/axios";

export const getStates = async () => {
  try {
    const response = await axios.get(`/combo/GetStates`, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRegions = async () => {
  try {
    const response = await axios.get(`/combo/GetRegions`, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCountiesByState = async (stateId) => {
  try {
    const response = await axios.get(`/combo/GetCountyByStateId/${stateId}`, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCityByZipcode = async (zipcode) => {
  try {
    const response = await axios.get(`/combo/GetCityByZipcode/${zipcode}`, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
