import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import typesData from "../enums/types.json";
import sexData from "../enums/sex.json";
import actionsData from "../enums/actions.json";
import intakesData from "../enums/intakes.json";
import countriesData from "../enums/countries.json";
import { getClientByDriverLicense } from "../api/client";
import { getInfractionByCountyAndType } from "../api/infraction";
import { saveCitation, validateCase, validateCitation } from "../api/case";
import {
  getStates,
  getCountiesByState,
  getCityByZipcode,
} from "../api/location";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import ReactDataGrid from "react-data-grid";
import { useHistory } from "react-router-dom";
import { generateOtp, validateOtp } from "../api/otp";
const types = typesData.types;
const sexs = sexData.sex;
const countries = countriesData.countries;
const actions = actionsData.actions;
const intakes = intakesData.intakes;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  headerSection: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
    backgroundColor: "#FFEF65",
    borderRadius: 7,
  },
  buttonSearch: {
    color: "#FFEF65",
    backgroundColor: "black",
    height: 50,
    width: "100%",
    fontWeight: "bold",
    fontSize: 16,
  },
  searchText: {
    width: "100%",
    height: 50,
    paddingRight: 20,
  },
  formLabel: {
    paddingRight: 30,
    paddingLeft: 20,
    fontWeight: "bold",
  },
  responseLabel: {
    textAlign: "center",
  },
  inputText: {
    width: "100%",
    height: 30,
    marginTop: 20,
  },
  addressText: {
    width: "100%",
    height: 30,
    marginTop: 20,
  },
  saveButton: {
    color: "#FFEF65",
    backgroundColor: "black",
    height: 50,
    width: 150,
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 20,
  },
  addButton: {
    color: "#FFEF65",
    backgroundColor: "black",
    height: 50,
    width: "100%",
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 20,
  },
  checkbox: {
    marginTop: 10,
  },
  modal: {
    backgroundColor: "#FFEF65",
    color: "black",
    textAlign: "center",
  },
  addModal: {
    backgroundColor: "#FFEF65",
    color: "black",
    textAlign: "center",
    width: 552,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  cellAction: {
    textAlign: "center",
  },
}));

function CasePage() {
  const classes = useStyles();
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  /** For Page **/
  const [isClientExist, setIsClientExist] = useState(false);
  const [isClientNotExist, setIsClientNotExist] = useState(false);
  const [showAddClient, setShowAddClient] = useState(false);
  const [showClientDetail, setShowClientDetail] = useState(true);
  const [clientFirstName, setClientFirstName] = useState("");
  const [clientLastName, setClientLastName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [infractions, setInfractions] = useState([]);
  const [states, setStates] = useState([]);
  const [counties, setCounties] = useState([]);
  const [cities, setCities] = useState([]);
  const [errorMap, setErrorMap] = useState({
    eLastName: false,
    eFirstName: false,
    eAddress1: false,
    eCity: false,
    eState: false,
    eCountry: false,
    eSex: false,
    eDateOfBirth: false,
    eDriverLicense: false,
    eCaseState: false,
    eCaseCounty: false,
    eCaseType: false,
    eCitationDate: false,
    eCaseAction: false,
    eCaseIntake: false,
    eComplyByDate: false,
    eCaseCitationNumber: false,
    eCaseInfraction: false,
    eCaseChargeAmount: false,
    eClientMobileNumberEdit: false,
    eCaseNumber: false,
    eLanguage: false,
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [openInfraction, setOpenInfraction] = useState(false);
  const [addInfractionBtn, setAddInfractionBtn] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [payAlert, setPayAlert] = useState(false);
  const [rows, setRows] = useState([]);
  const [caseRows, setCaseRows] = useState([]);
  const [backShow, setBackShow] = useState(false);
  const [copyText, setCopyText] = useState("Copy");
  const [savedSuccess, setSavedSuccess] = useState(false);
  const [userId, setUserId] = useState(params.get("userid"));
  const [token, setToken] = useState(params.get("otptoken"));
  const [disableSearch, setDisableSearch] = useState(false);
  const [showMobileNumberEdit, setShowMobileNumberEdit] = useState(false);
  const [showEditPhoneBtn, setShowEditPhoneBtn] = useState(false);
  const [showPage, setShowPage] = useState(false);

  /** For Case **/
  const [driverLicense, setDriverLicense] = useState("");
  const [clientId, setClientId] = useState("");
  const [stateId, setStateId] = useState(
    process.env.REACT_APP_TTC_DEFAULT_STATE
  );
  const [countyId, setCountyId] = useState(
    process.env.REACT_APP_TTC_DEFAULT_COUNTY
  );
  const [typeId, setTypeId] = useState("0");
  const [typeDesc, setTypeDesc] = useState("");
  const [infractionId, setInfractionId] = useState("0");
  const [caseNumber, setCaseNumber] = useState("");
  const [caseActionId, setCaseActionId] = useState("0");
  const [caseActionDesc, setCaseActionDesc] = useState("");
  const [citationDate, setCitationDate] = useState("");
  const [accident, setAccident] = useState("0");
  const [injury, setInjury] = useState("0");
  const [fatality, setFatality] = useState("0");
  const [caseIntakeId, setCaseIntakeId] = useState(
    process.env.REACT_APP_TTC_DEFAULT_CASE_INTAKE
  );
  const [chargeAmount, setChargeAmount] = useState("");
  const [infractionDesc, setInfractionDesc] = useState("");
  const [paymentPageUrl, setPaymentPageUrl] = useState("");
  const [citationNumber, setCitationNumber] = useState("");
  const [complyByDate, setComplyByDate] = useState("");
  const [reason, setReason] = useState("");
  const [barcodeId, setBarcodeId] = useState(0);
  const [quoteGroupId, setQuoteGroupId] = useState("");

  /** For Clients **/
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [clientCity, setClientCity] = useState("0");
  const [clientStateId, setClientStateId] = useState("0");
  const [zipcode, setZipcode] = useState("");
  const [clientCountryId, setClientCountryId] = useState(
    process.env.REACT_APP_TTC_DEFAULT_COUNTRY
  );
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [clientDriverLicense, setClientDriverLicense] = useState("");
  const [sex, setSex] = useState("0");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [stateIssuedId, setStateIssuedId] = useState("0");
  const [mobileNumberEdit, setMobileNumberEdit] = useState("");
  const [language, setLanguage] = useState("0");

  /** For Grid Cases **/
  const [showGridCases, setShowGridCases] = useState(false);
  const [openAlertAddCase, setOpenAlertAddCase] = useState(false);
  const [disableSave, setDisableSave] = useState(false)

  const columns = [
    { key: "action", name: "Delete", float: "left", width: 100 },
    {
      key: "citationNumber",
      name: "# Citation",
      editable: false,
      width: "10%",
    },
    { key: "infraction", name: "Infraction", editable: false, width: "40%" },
    { key: "amount", name: "Amount ($)", editable: false, width: "10%" },
    {
      key: "reason",
      name: "Reason for Adjustment",
      editable: false,
      width: "40%",
    },
    { key: "infractionId", width: -1, hidden: true },
  ];

  const caseColumns = [
    { key: "barcodeId", name: "Barcode Id", editable: false, width: "20%" },
    { key: "caseNumber", name: "Case Number", editable: false, width: "30%" },
    { key: "type", name: "Type", editable: false, width: "20%" },
    {
      key: "citationDate",
      name: "Citation Date",
      editable: false,
      width: "20%",
    },
    { key: "caseAction", name: "Case Action", editable: false, width: "20%" },
  ];

  const handleChangeState = (stateId) => {
    setStateId(stateId);
    getCountiesDropdown(stateId);
  };

  const handleChangeZipcode = (e) => {
    setZipcode(e);
    getCityByZipcodeDropdow(e);
  };

  const handleSetInfraction = (infractionId) => {
    setInfractionId(infractionId);
    var res = infractions.filter(function (item) {
      if (item.id == infractionId) {
        return item;
      }
    });
    setChargeAmount(res[0].price);
    setInfractionDesc(res[0].describeInfraction);
  };

  const handleSetType = (typeId) => {
    setTypeId(typeId);
    var res = types.filter(function (item) {
      if (item.typeId == typeId) {
        return item;
      }
    });
    setTypeDesc(res[0].name);
    setInfractionId("0");
    setChargeAmount("");
  };

  const handleSetCaseAction = (caseActionId) => {
    setCaseActionId(caseActionId);
    var res = actions.filter(function (item) {
      if (item.caseActionId == caseActionId) {
        return item;
      }
    });
    setCaseActionDesc(res[0].description);
  };

  const handleSetCounty = (countyId) => {
    setCountyId(countyId);
    setInfractionId("0");
    setChargeAmount("");
  };

  const handleClose = async () => {
    setOpenAlert(false);
    if (savedSuccess === true) {
      var newToken = uuidv4();
      await generateOtp({
        userName: userId,
        token: newToken,
      });
      if (isClientExist != false)
        history.push("/caselist?userid=" + userId + "&otptoken=" + newToken);
      else history.push("/");
    }
  };

  const handleCancel = async () => {
    var newToken = uuidv4();
    await generateOtp({
      userName: userId,
      token: newToken,
    });
    history.push("/caselist?userid=" + userId + "&otptoken=" + newToken);
  };

  const handleCloseInfraction = () => {
    setOpenInfraction(false);
  };

  useEffect(() => {
    validateOtpPage();
  }, []);

  // useEffect(() => {
  //   getStatesDropdown();
  // }, []);

  useEffect(() => {
    getCountiesDropdown(stateId, showPage);
  }, [stateId, showPage]);

  useEffect(() => {
    getInfractions(countyId, typeId, showPage);
  }, [countyId, typeId, showPage]);

  const validateOtpPage = async () => {
    const response = await validateOtp({
      userName: userId,
      token: token,
    });
    if (typeof response == "undefined") {
      history.push("/unauthorized");
    } else {
      setShowPage(true);
      getStatesDropdown();
    }
  };

  const getInfractions = async (countyId, typeId, showPage) => {
    try {
      if (showPage) {
        const response = await getInfractionByCountyAndType(countyId, typeId);
        if (typeof response != "undefined") {
          setInfractions(response);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStatesDropdown = async () => {
    try {
      const response = await getStates();
      if (typeof response != "undefined") {
        setStates(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCountiesDropdown = async (stateId, showPage) => {
    try {
      if (showPage) {
        const response = await getCountiesByState(stateId);
        if (typeof response != "undefined") {
          setCounties(response);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCityByZipcodeDropdow = async (zipcode) => {
    try {
      const response = await getCityByZipcode(zipcode);
      if (typeof response != "undefined") {
        setCities(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchClient = async () => {
    try {
      const response = await getClientByDriverLicense(driverLicense);
      if (typeof response === "undefined") {
        setIsClientExist(false);
        setIsClientNotExist(true);
        setOpenAlert(true);
        setClientId("");
        setClientFirstName("");
        setClientLastName("");
        setClientEmail("");
        setClientPhone("");
        setShowEditPhoneBtn(false);
        setAlertMessage("Client does not exist.");
        setClientDriverLicense(driverLicense);
      }
      if (typeof response.id === "undefined") {
        setOpenAlert(true);
        setAlertMessage("Client does not exist.");
        setClientDriverLicense(driverLicense);
        setClientId("");
        setClientFirstName("");
        setClientLastName("");
        setClientEmail("");
        setClientPhone("");
        setShowEditPhoneBtn(false);
        setIsClientExist(false);
        setIsClientNotExist(true);
      } else {
        if (response.problemClient === 1){
          setAlertMessage("Problem client is not allowed.");
          setIsClientExist(false);
          setIsClientNotExist(false);
          setOpenAlert(true);
          setClientId("");
          setClientFirstName("");
          setClientLastName("");
          setClientEmail("");
          setClientPhone("");
          setShowEditPhoneBtn(false);
          setClientDriverLicense(driverLicense);
        } else {
          if (response.pastDueAmount > 0){
            setAlertMessage("Client with Past Due is not allowed");
            setIsClientExist(false);
            setIsClientNotExist(false);
            setOpenAlert(true);
            setClientId("");
            setClientFirstName("");
            setClientLastName("");
            setClientEmail("");
            setClientPhone("");
            setShowEditPhoneBtn(false);
            setClientDriverLicense(driverLicense);
          } else {
            setClientId(response.id);
            setClientFirstName(response.firstName);
            setClientLastName(response.lastName);
            setClientEmail(response.email);
            setClientPhone(response.mobileNumber);
            setMobileNumberEdit(response.mobileNumber);
            setShowClientDetail(true);
            setShowAddClient(false);
            setIsClientExist(true);
            setIsClientNotExist(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addCaseTicket = () => {
    setOpenInfraction(true);
  };

  const addInfraction = async () => {
    const errorRes = {
      eCaseCitationNumber: false,
      eCaseInfraction: false,
      eCaseChargeAmount: false,
    };
    if (citationNumber === null || citationNumber === "") {
      errorRes.eCaseCitationNumber = true;
    }
    if (infractionId === null || infractionId === "0") {
      errorRes.eCaseInfraction = true;
    }
    if (chargeAmount === null || chargeAmount === "") {
      errorRes.eCaseChargeAmount = true;
    }
    if (Object.values(errorRes).includes(true)) {
      setErrorMap(errorRes);
      setOpenAlert(true);
      setAlertMessage("Complete the required fields please");
      return;
    }
    if (citationNumber !== null || citationNumber !== "") {
      const validation = await validateCitation(citationNumber);
      if (validation !== true) {
        setRows(
          rows.concat([
            {
              citationNumber: citationNumber,
              infraction: infractionDesc,
              amount: parseFloat(chargeAmount).toFixed(2),
              action: "",
              infractionId: infractionId,
              reason: reason,
            },
          ])
        );
        ReactDataGrid.rowsCount = rows.length + 1;
        setOpenInfraction(false);
        setInfractionId("0");
        setCitationNumber("");
        setInfractionDesc("");
        setChargeAmount("");
        setReason("");
      } else {
        setOpenAlert(true);
        setAlertMessage("Citation Number already exist");
        setErrorMap({ eCaseCitationNumber: true });
      }
    }
  };

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    setRows((rows) => {
      const newRow = rows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        newRow[i] = { ...newRow[i], ...updated };
      }
      return newRow;
    });
  };

  const onGridCaseRowsUpdated = ({ fromRow, toRow, updated }) => {
    setCaseRows((caseRows) => {
      const newRow = caseRows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        newRow[i] = { ...newRow[i], ...updated };
      }
      return newRow;
    });
  };

  const getCellActions = (column, row) => {
    const cellActions = [
      {
        icon: (
          <span style={{ fontWeight: "bold", display: "flex", fontSize: 22 }}>
            x
          </span>
        ),
        callback: () => {
          const currentRows = rows;
          let rowsTemp = [];
          currentRows.forEach((rowTemp) => {
            if (rowTemp.infractionId != row.infractionId) {
              rowsTemp.push(rowTemp);
            }
          });
          setRows(rowsTemp);
        },
      },
    ];
    return column.key === "action" ? cellActions : null;
  };

  const addClient = () => {
    setShowAddClient(true);
    setShowClientDetail(false);
    setIsClientExist(true);
    setIsClientNotExist(false);
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const saveCitationHandle = async () => {
    setDisableSave(true)
    const validation = await validateCase(caseNumber);
    if (validation !== true) {
      setErrorMap({ eCaseNumber: false });
      setBackShow(true);
      let isAccidentBit;
      let isInjuryBit;
      let isFatalityBit;
      let tickets = [];

      var groupId;

      if (showGridCases != true) {
        groupId = uuidv4();
      } else {
        groupId = quoteGroupId;
      }

      setQuoteGroupId(groupId);

      if (accident !== "0") isAccidentBit = true;
      else isAccidentBit = false;

      if (injury !== "0") isInjuryBit = true;
      else isInjuryBit = false;

      if (fatality !== "0") isFatalityBit = true;
      else isFatalityBit = false;

      if (rows.length > 0) {
        for (let indexRows = 0; indexRows < rows.length; indexRows++) {
          let caseTickets = {};
          caseTickets.ticketNumber = rows[indexRows]["citationNumber"];
          caseTickets.infractionId = parseInt(rows[indexRows]["infractionId"]);
          caseTickets.chargeAmount = parseFloat(rows[indexRows]["amount"]);
          caseTickets.reason = rows[indexRows]["reason"];
          tickets.push(caseTickets);
        }
      }

      const payloadClient = {
        userId: parseInt(userId),
        client: {
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          driverLicense: clientDriverLicense,
          mobileNumber: mobileNumber,
          email: email,
          zipCode: zipcode,
          countryId: clientCountryId,
          address1: address1,
          address2: address2,
          isActive: 1,
          city: clientCity,
          stateAbbr: clientStateId,
          licenseStateId: parseInt(stateIssuedId),
          sexId: parseInt(sex),
          dateOfBirth: dateOfBirth,
          languageId: parseInt(language),
        },
        case: {
          stateId: parseInt(stateId),
          countyId: parseInt(countyId),
          caseNumber: caseNumber,
          infractionId: parseInt(infractionId),
          typeId: parseInt(typeId),
          citationDate: citationDate,
          complyByDate: complyByDate,
          caseActionId: parseInt(caseActionId),
          isAccident: isAccidentBit,
          isInjury: isInjuryBit,
          isFatality: isFatalityBit,
          quoteGroupId: groupId,
        },
        caseTickets: tickets,
      };

      const payloadCase = {
        userId: parseInt(userId),
        case: {
          stateId: parseInt(stateId),
          countyId: parseInt(countyId),
          caseNumber: caseNumber,
          clientId: clientId,
          infractionId: parseInt(infractionId),
          typeId: parseInt(typeId),
          citationDate: citationDate,
          complyByDate: complyByDate,
          caseActionId: parseInt(caseActionId),
          isAccident: isAccidentBit,
          isInjury: isInjuryBit,
          isFatality: isFatalityBit,
          quoteGroupId: groupId,
          caseIntakeId: 11,
        },
        client: {
          mobileNumber: mobileNumberEdit,
        },
        caseTickets: tickets,
      };

      let payload;
      if (showAddClient === true) payload = payloadClient;
      else payload = payloadCase;

      const errorRes = {
        eLastName: false,
        eFirstName: false,
        eAddress1: false,
        eCity: false,
        eState: false,
        eCountry: false,
        eSex: false,
        eDateOfBirth: false,
        eDriverLicense: false,
        eCaseState: false,
        eCaseCounty: false,
        eCaseType: false,
        eCitationDate: false,
        eComplyByDate: false,
        eCaseAction: false,
        eCaseIntake: false,
        eClientMobileNumberEdit: false,
        eLanguage: false,
      };

      try {
        var today = new Date();
        var date = new Date(citationDate);
        var date2 = new Date(complyByDate);
        if (showAddClient === true) {
          if (lastName === null || lastName === "") {
            errorRes.eLastName = true;
          }
          if (firstName === null || firstName === "") {
            errorRes.eFirstName = true;
          }
          if (address1 === null || address1 === "") {
            errorRes.eAddress1 = true;
          }
          if (clientCity === null || clientCity === "0") {
            errorRes.eCity = true;
          }
          if (clientStateId === null || clientStateId === "0") {
            errorRes.eState = true;
          }
          if (clientCountryId === null || clientCountryId === "0") {
            errorRes.eCountry = true;
          }
          if (sex === null || sex === "0") {
            errorRes.eSex = true;
          }
          if (dateOfBirth === null || dateOfBirth === "") {
            errorRes.eDateOfBirth = true;
          }
          if (clientDriverLicense === null || clientDriverLicense === "") {
            errorRes.eDriverLicense = true;
          }
          if (language === null || language === "0") {
            errorRes.eLanguage = true;
          }
        }
        if (showMobileNumberEdit === true) {
          if (mobileNumberEdit === null || mobileNumberEdit === "") {
            errorRes.eClientMobileNumberEdit = true;
          }
        }
        if (stateId === null || stateId === "0") {
          errorRes.eCaseState = true;
        }
        if (countyId === null || countyId === "0") {
          errorRes.eCaseCounty = true;
        }
        if (typeId === null || typeId === "0") {
          errorRes.eCaseType = true;
        }
        if (citationDate === null || citationDate === "") {
          errorRes.eCitationDate = true;
        }
        if (complyByDate === null || complyByDate === "") {
          errorRes.eComplyByDate = true;
        }
        if (date2.getFullYear() < 1973) {
          errorRes.eComplyByDate = true;
          setBackShow(false);
          setOpenAlert(true);
          setDisableSave(false);
          setAlertMessage("Comply by date format is incorrect");
        }
        if (date.getFullYear() < 1973) {
          errorRes.eCitationDate = true;
          setBackShow(false);
          setOpenAlert(true);
          setDisableSave(false);
          setAlertMessage("Citation date format is incorrect");
        }
        if (date > today) {
          errorRes.eCitationDate = true;
          setBackShow(false);
          setOpenAlert(true);
          setDisableSave(false);
          setAlertMessage(
            "Citation date must be before the current date or today"
          );
        }
        if (caseActionId === null || caseActionId === "0") {
          errorRes.eCaseAction = true;
        }
        // if (caseIntakeId === null || caseIntakeId === "0") {
        //   errorRes.eCaseIntake = true;
        // }
        if (Object.values(errorRes).includes(true)) {
          setErrorMap(errorRes);
          setBackShow(false);
          setDisableSave(false);
          return;
        }
        const response = await saveCitation(payload);
        if (typeof response != "undefined") {
          setBarcodeId(response);
          setOpenAlertAddCase(true);
          // setBackShow(false);
          // setPayAlert(true);
          // setPaymentPageUrl('https://ttc-florida-web.azurewebsites.net/casepayment?barcodeid=' + response.toString(36))
          // setOpenAlert(true);
          // setAlertMessage('Saved successfully');
          // setSavedSuccess(true);
        } else {
          setBackShow(false);
          setOpenAlert(true);
          setDisableSave(false);
          setAlertMessage("An error occurred. Retry");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpenAlert(true);
      setDisableSave(false);
      setAlertMessage("The ticket already exist");
      setErrorMap({ eCaseNumber: true });
    }
  };

  const handleAddCase = () => {
    setBackShow(false);
    setDisableSave(false);
    setDisableSearch(true);
    setOpenAlertAddCase(false);
    setCaseRows(
      caseRows.concat([
        {
          barcodeId: barcodeId,
          type: typeDesc,
          caseNumber: caseNumber,
          citationDate: citationDate,
          caseAction: caseActionDesc,
        },
      ])
    );
    ReactDataGrid.rowsCount = caseRows.length + 1;
    setShowGridCases(true);
    setStateId(process.env.REACT_APP_TTC_DEFAULT_STATE);
    setCountyId(process.env.REACT_APP_TTC_DEFAULT_COUNTY);
    setTypeId("0");
    setInfractionId("0");
    setCaseNumber("");
    setCaseActionId("0");
    setCitationDate("");
    setAccident("0");
    setInjury("0");
    setFatality("0");
    setCaseIntakeId(process.env.REACT_APP_TTC_DEFAULT_CASE_INTAKE);
    setChargeAmount("");
    setInfractionDesc("");
    setPaymentPageUrl("");
    setCitationNumber("");
    setComplyByDate("");
    setReason("");
    setBarcodeId(0);
    setRows([]);

    if (showAddClient === true) {
      setShowAddClient(false);
      setDriverLicense(clientDriverLicense);
      var btn = document.getElementById("search");
      btn.click();
      setShowClientDetail(true);
    }
  };

  const handleValidateCase = async (caseNumber) => {
    const response = await validateCase(caseNumber);
    return response;
    // if(response !== false){
    //     // setOpenAlert(true);
    //     // setAlertMessage('The ticket already exist');
    //     // errorMap.caseNumber = true;
    //     return false;
    // } else {
    //     return true;
    // }
  };

  const handleOnlyOneCase = () => {
    setOpenAlert(false);
    setBackShow(false);
    setPayAlert(true);
    setPaymentPageUrl(
      process.env.REACT_APP_TTC_PAYMENT_PAGE_URL + quoteGroupId
    );
    setOpenAlert(true);
    setAlertMessage("Saved successfully");
    setSavedSuccess(true);
  };

  useEffect(() => {
    if (clientPhone !== "") setShowEditPhoneBtn(true);
  }, [clientPhone]);

  const handleOnChangeCitationDate = (e) => {
    var invoiceDate = document.getElementById("citationDateInput").value;
    var days = 30;

    invoiceDate = invoiceDate.split("-");
    invoiceDate = new Date(invoiceDate[0], invoiceDate[1] - 1, invoiceDate[2]);
    invoiceDate.setDate(invoiceDate.getDate() + days);
    const year = invoiceDate.getFullYear();
    const monthWithOffset = invoiceDate.getUTCMonth() + 1;
    const month =
      monthWithOffset.toString().length < 2
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
      invoiceDate.getUTCDate().toString().length < 2
        ? `0${invoiceDate.getUTCDate()}`
        : invoiceDate.getUTCDate();

    const materialDateInput = `${year}-${month}-${date}`;
    setCitationDate(e);
    setComplyByDate(materialDateInput);
  };

  return (
    <div>
      {showPage ? (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                label="Search by Driver License"
                className={classes.searchText}
                variant="outlined"
                value={driverLicense}
                disabled={disableSearch}
                onChange={(e) => setDriverLicense(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                id="search"
                variant="contained"
                className={classes.buttonSearch}
                onClick={searchClient}
              >
                Search
              </Button>
            </Grid>
            {isClientNotExist && (
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  className={classes.buttonSearch}
                  onClick={addClient}
                >
                  Add Client
                </Button>
              </Grid>
            )}
            {showClientDetail && (
              <>
                <Grid item xs={12}>
                  <div className={classes.headerSection}>CLIENT DETAILS</div>
                </Grid>
                <Grid item xs={6}>
                  <label className={classes.formLabel}>First Name:</label>
                  <label className={classes.responseLabel}>
                    {clientFirstName}
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <label className={classes.formLabel}>Last Name:</label>
                  <label className={classes.responseLabel}>
                    {clientLastName}
                  </label>
                </Grid>
                {!showMobileNumberEdit && (
                  <Grid item xs={6}>
                    <label className={classes.formLabel}>Mobile Number:</label>
                    <label className={classes.responseLabel}>
                      {clientPhone}
                    </label>
                    {showEditPhoneBtn && (
                      <Button
                        variant="contained"
                        className={classes.buttonSearch}
                        style={{ width: 100, height: 30, marginLeft: 20 }}
                        onClick={() => setShowMobileNumberEdit(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                )}
                {showMobileNumberEdit && (
                  <Grid item xs={6}>
                    <MuiPhoneNumber
                      defaultCountry={"us"}
                      value={mobileNumberEdit}
                      variant="outlined"
                      label="Mobile Number"
                      className={classes.inputText}
                      error={errorMap.eClientMobileNumberEdit}
                      style={{
                        width: 350,
                        height: 30,
                        marginLeft: 20,
                        marginBottom: 30,
                      }}
                      onChange={(e) => setMobileNumberEdit(e)}
                    />
                    <Button
                      variant="contained"
                      className={classes.buttonSearch}
                      style={{
                        width: 100,
                        height: 30,
                        marginLeft: 20,
                        marginTop: 30,
                      }}
                      onClick={() => setShowMobileNumberEdit(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <label className={classes.formLabel}>E-mail:</label>
                  <label className={classes.responseLabel}>{clientEmail}</label>
                </Grid>
              </>
            )}
            {showGridCases && (
              <>
                <Grid item xs={12}>
                  <div className={classes.headerSection}>CASE LIST</div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <ReactDataGrid
                      minHeight={200}
                      columns={caseColumns}
                      rowGetter={(i) => caseRows[i]}
                      rowsCount={caseRows.length}
                      onGridRowsUpdated={onGridCaseRowsUpdated}
                      enableCellSelect={true}
                    />
                  </div>
                </Grid>
              </>
            )}
            {showAddClient && (
              <>
                <Grid item xs={12}>
                  <div className={classes.headerSection}>CLIENT DETAILS</div>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Last Name"
                    required
                    error={errorMap.eLastName}
                    className={classes.inputText}
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setErrorMap({ ...errorMap, eLastName: false });
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="First Name"
                    required
                    error={errorMap.eFirstName}
                    className={classes.inputText}
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setErrorMap({ ...errorMap, eFirstName: false });
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Middle Name"
                    className={classes.inputText}
                    variant="outlined"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Email"
                    className={classes.inputText}
                    variant="outlined"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Address 1 (Street Address, P.O Box, Company Name, c/o)"
                    required
                    error={errorMap.eAddress1}
                    className={classes.addressText}
                    variant="outlined"
                    value={address1}
                    onChange={(e) => {
                      setAddress1(e.target.value);
                      setErrorMap({ ...errorMap, eAddress1: false });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Address 2 (Apartment, suite, unit, building, floor, etc.)"
                    className={classes.addressText}
                    variant="outlined"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Zipcode"
                    className={classes.inputText}
                    variant="outlined"
                    required
                    value={zipcode}
                    onChange={(e) => handleChangeZipcode(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    label="City"
                    SelectProps={{
                      native: true,
                    }}
                    required
                    error={errorMap.eCity}
                    variant="outlined"
                    value={clientCity}
                    className={classes.inputText}
                    onChange={(e) => {
                      setClientCity(e.target.value);
                      setErrorMap({ ...errorMap, eCity: false });
                    }}
                  >
                    <option key="0" disabled value="0">
                      --Select--
                    </option>
                    {cities.map((option) => (
                      <option key={option.cityName} value={option.cityName}>
                        {option.cityName}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    label="State"
                    SelectProps={{
                      native: true,
                    }}
                    required
                    error={errorMap.eState}
                    variant="outlined"
                    className={classes.inputText}
                    value={clientStateId}
                    onChange={(e) => {
                      setClientStateId(e.target.value);
                      setErrorMap({ ...errorMap, eState: false });
                    }}
                  >
                    <option key="0" value="0" disabled>
                      --Select--
                    </option>
                    {states.map((option) => (
                      <option key={option.id} value={option.stateAbbr}>
                        {option.stateName}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    label="Country"
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    required
                    error={errorMap.eCountry}
                    className={classes.inputText}
                    value={clientCountryId}
                    onChange={(e) => {
                      setClientCountryId(e.target.value);
                      setErrorMap({ ...errorMap, eCountry: false });
                    }}
                  >
                    <option key="0" disabled value="0">
                      --Select--
                    </option>
                    {countries.map((option) => (
                      <option key={option.countryId} value={option.countryId}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <MuiPhoneNumber
                    defaultCountry={"us"}
                    value={mobileNumber}
                    variant="outlined"
                    label="Mobile Number"
                    className={classes.inputText}
                    onChange={(e) => setMobileNumber(e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    label="Sex"
                    SelectProps={{
                      native: true,
                    }}
                    required
                    error={errorMap.eSex}
                    variant="outlined"
                    value={sex}
                    className={classes.inputText}
                    onChange={(e) => {
                      setSex(e.target.value);
                      setErrorMap({ ...errorMap, eSex: false });
                    }}
                  >
                    <option key="0" value="0" disabled>
                      --Select--
                    </option>
                    {sexs.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="date"
                    label="Date Of Birth"
                    type="date"
                    variant="outlined"
                    required
                    error={errorMap.eDateOfBirth}
                    className={classes.inputText}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dateOfBirth}
                    onChange={(e) => {
                      setDateOfBirth(e.target.value);
                      setErrorMap({ ...errorMap, eDateOfBirth: false });
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Driver License"
                    className={classes.inputText}
                    variant="outlined"
                    required
                    error={errorMap.eDriverLicense}
                    value={clientDriverLicense}
                    onChange={(e) => {
                      setClientDriverLicense(e.target.value);
                      setErrorMap({ ...errorMap, eDriverLicense: false });
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    label="State Issued"
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    value={stateIssuedId}
                    className={classes.inputText}
                    onChange={(e) => setStateIssuedId(e.target.value)}
                  >
                    <option key="0" disabled value="0">
                      --Select--
                    </option>
                    {states.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.stateAbbr}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    label="Language"
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    required
                    error={errorMap.eLanguage}
                    className={classes.inputText}
                    value={language}
                    onChange={(e) => {
                      setLanguage(e.target.value);
                      setErrorMap({ ...errorMap, eLanguage: false });
                    }}
                  >
                    <option key="0" disabled value="0">
                      --Select--
                    </option>
                    <option key="1" value="1">
                      English
                    </option>
                    <option key="2" value="2">
                      Spanish
                    </option>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ paddingTop: 25 }} />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <div className={classes.headerSection}>ADD CASE DETAILS</div>
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="State"
                SelectProps={{
                  native: true,
                }}
                required
                error={errorMap.eCaseState}
                InputLabelProps={{ shrink: !!stateId }}
                variant="outlined"
                className={classes.inputText}
                value={stateId}
                onChange={(e) => {
                  handleChangeState(e.target.value);
                  setErrorMap({ ...errorMap, eCaseState: false });
                }}
              >
                <option key="0" disabled value="0">
                  --Select--
                </option>
                {states.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.stateName}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="County"
                SelectProps={{
                  native: true,
                }}
                required
                error={errorMap.eCaseCounty}
                variant="outlined"
                InputLabelProps={{ shrink: !!countyId }}
                value={countyId}
                className={classes.inputText}
                onChange={(e) => {
                  handleSetCounty(e.target.value);
                  setErrorMap({ ...errorMap, eCaseCounty: false });
                }}
              >
                <option key="0" disabled value="0">
                  --Select--
                </option>
                {counties.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.county}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Case Number"
                className={classes.inputText}
                error={errorMap.eCaseNumber}
                variant="outlined"
                value={caseNumber}
                onChange={(e) => {
                  setCaseNumber(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 32) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Type"
                SelectProps={{
                  native: true,
                }}
                value={typeId}
                required
                error={errorMap.eCaseType}
                onChange={(e) => {
                  handleSetType(e.target.value);
                  setErrorMap({ ...errorMap, eCaseType: false });
                }}
                variant="outlined"
                className={classes.inputText}
              >
                <option key="0" disabled value="0">
                  --Select--
                </option>
                {types.map((option) => (
                  <option key={option.typeId} value={option.typeId}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="citationDateInput"
                label="Citation Date"
                type="date"
                variant="outlined"
                className={classes.inputText}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                error={errorMap.eCitationDate}
                value={citationDate}
                onChange={(e) => {
                  handleOnChangeCitationDate(e.target.value);
                  setErrorMap({ ...errorMap, eCitationDate: false });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Case Action"
                SelectProps={{
                  native: true,
                }}
                value={caseActionId}
                required
                error={errorMap.eCaseAction}
                onChange={(e) => {
                  handleSetCaseAction(e.target.value);
                  setErrorMap({ ...errorMap, eCaseAction: false });
                }}
                variant="outlined"
                className={classes.inputText}
              >
                <option key="0" disabled value="0">
                  --Select--
                </option>
                {actions.map((option) => (
                  <option key={option.caseActionId} value={option.caseActionId}>
                    {option.description}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Accident"
                SelectProps={{
                  native: true,
                }}
                value={accident}
                onChange={(e) => {
                  setAccident(e.target.value);
                }}
                variant="outlined"
                className={classes.inputText}
              >
                <option key="No" value="0">
                  No
                </option>
                <option key="Yes" value="1">
                  Yes
                </option>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Injury"
                SelectProps={{
                  native: true,
                }}
                value={injury}
                onChange={(e) => {
                  setInjury(e.target.value);
                }}
                variant="outlined"
                className={classes.inputText}
              >
                <option key="No" value="0">
                  No
                </option>
                <option key="Yes" value="1">
                  Yes
                </option>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Fatality"
                SelectProps={{
                  native: true,
                }}
                value={fatality}
                onChange={(e) => {
                  setFatality(e.target.value);
                }}
                variant="outlined"
                className={classes.inputText}
              >
                <option key="No" value="0">
                  No
                </option>
                <option key="Yes" value="1">
                  Yes
                </option>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Case Intake"
                SelectProps={{
                  native: true,
                }}
                required
                disabled
                error={errorMap.eCaseIntake}
                value={caseIntakeId}
                onChange={(e) => {
                  setCaseIntakeId(e.target.value);
                  setErrorMap({ ...errorMap, eCaseIntake: false });
                }}
                variant="outlined"
                className={classes.inputText}
              >
                {intakes.map((option) => (
                  <option key={option.caseIntakeId} value={option.caseIntakeId}>
                    {option.description}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="complyByDateInput"
                label="Comply by Date"
                type="date"
                variant="outlined"
                className={classes.inputText}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                error={errorMap.eComplyByDate}
                value={complyByDate}
                onChange={(e) => {
                  setComplyByDate(e.target.value);
                  setErrorMap({ ...errorMap, eComplyByDate: false });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <div className={classes.headerSection}>CASE TICKETS</div>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={addCaseTicket}
                className={classes.addButton}
              >
                Add Infraction
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div>
                <ReactDataGrid
                  minHeight={200}
                  columns={columns}
                  rowGetter={(i) => rows[i]}
                  rowsCount={rows.length}
                  onGridRowsUpdated={onGridRowsUpdated}
                  enableCellSelect={true}
                  getCellActions={getCellActions}
                />
              </div>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              {isClientExist && (
                <Button
                  variant="contained"
                  className={classes.saveButton}
                  onClick={saveCitationHandle}
                  disabled={disableSave}
                >
                  Save
                </Button>
              )}
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              {isClientExist && (
                <Button
                  variant="contained"
                  className={classes.saveButton}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
          <Dialog
            open={openAlert}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title" className={classes.modal}>
              {alertMessage}
            </DialogTitle>
            {payAlert && (
              <DialogContent>
                <DialogContentText style={{ color: "black" }}>
                  <b>Note:</b>{" "}
                  <label style={{ color: "black" }}>
                    Click "Copy" button to copy the payment URL.
                  </label>
                </DialogContentText>
              </DialogContent>
            )}
            <DialogActions>
              {payAlert && (
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(paymentPageUrl);
                    setCopyText("Copied!");
                  }}
                  className={classes.buttonSearch}
                  autoFocus
                >
                  {copyText}
                </Button>
              )}
              <Button
                onClick={handleClose}
                className={classes.buttonSearch}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openAlertAddCase}
            onClose={handleOnlyOneCase}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="dialog-title" className={classes.modal}>
              Add case
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Do you want add another case?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleAddCase}
                className={classes.buttonSearch}
                autoFocus
              >
                Yes
              </Button>
              <Button
                onClick={handleOnlyOneCase}
                className={classes.buttonSearch}
                autoFocus
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openInfraction}
            onClose={handleCloseInfraction}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              id="responsive-dialog-title"
              className={classes.addModal}
            >
              Add Infraction
            </DialogTitle>
            <DialogContent style={{ height: 360 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Citation Number"
                    className={classes.inputText}
                    variant="outlined"
                    value={citationNumber}
                    required
                    error={errorMap.eCaseCitationNumber}
                    onChange={(e) => {
                      setCitationNumber(e.target.value);
                      setErrorMap({ ...errorMap, eCaseCitationNumber: false });
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 32) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    label="Infraction"
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    className={classes.inputText}
                    value={infractionId}
                    required
                    error={errorMap.eCaseInfraction}
                    onChange={(e) => {
                      handleSetInfraction(e.target.value);
                      setAddInfractionBtn(true);
                      setErrorMap({ ...errorMap, eCaseInfraction: false });
                    }}
                  >
                    <option key="0" disabled value="0">
                      --Select--
                    </option>
                    {infractions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.describeInfraction}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <CurrencyTextField
                    label="Charge Amount"
                    className={classes.inputText}
                    style={{ paddingBotton: 50 }}
                    variant="outlined"
                    value={chargeAmount}
                    digitGroupSeparator=""
                    required
                    selectOnFocus={false}
                    error={errorMap.eCaseChargeAmount}
                    currencySymbol="$"
                    onChange={(e) => {
                      setChargeAmount(e.target.value);
                      setErrorMap({ ...errorMap, eCaseChargeAmount: false });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Reason for Adjustment"
                    className={classes.inputText}
                    variant="outlined"
                    multiline
                    rows={4}
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {addInfractionBtn && (
                <Button
                  onClick={addInfraction}
                  className={classes.buttonSearch}
                  autoFocus
                >
                  Add
                </Button>
              )}
              <Button
                onClick={handleCloseInfraction}
                className={classes.buttonSearch}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Backdrop className={classes.backdrop} open={backShow}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h1>Loading...</h1>
        </div>
      )}
    </div>
  );
}

export default CasePage;
