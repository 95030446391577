import axios from "../utils/axios";

export const validateOtp = async (payload) => {
  try {
    const response = await axios.post(`/OTP/ValidateToken`, payload, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const generateOtp = async (payload) => {
  try {
    const response = await axios.post(`/OTP/RegisterToken`, payload, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};