import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import HomePage from './pages/HomePage';
import CasePage from './pages/CasePage';
import CaseListPage from './pages/CaseListPage';
import PaymentPage from './pages/PaymentPage';
import PaymentLanding from './pages/PaymentLanding';
import QuoteListPage from './pages/QuoteListPage';
import { ROUTES } from './utils/constants';
import NewQuotePage from './pages/NewQuotePage';
import Unauthorized from './pages/Unauthorized';

function App() {
  return (
    <Router>
      <Switch>
        <Route path={`${ROUTES.CASE_EDITOR_ROUTE}`} component={CasePage} />
        <Route path={`${ROUTES.PAYMENT_ROUTE}`} component={PaymentPage} />
        <Route path={`${ROUTES.PAYMENT_LANDING}`} component={PaymentLanding} />
        <Route path={`${ROUTES.CASE_LIST}`} component={CaseListPage} />
        <Route path={`${ROUTES.NEW_QUOTE_LIST}`} component={QuoteListPage} />
        <Route path={`${ROUTES.NEW_QUOTE}`} component={NewQuotePage} />
        <Route path={`${ROUTES.UNAUTHORIZED}`} component={Unauthorized} />
        <Route exact path="/" component={HomePage} />
      </Switch>
    </Router>
  );
}

export default App;
