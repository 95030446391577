import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button
} from '@material-ui/core';
import IMAGES from '../utils/images';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        paddingTop: 50,
        paddingLeft: 20,
        paddingRight: 20
    },
    button: {
        color: '#FFEF65',
        backgroundColor: 'black',
        height: 50,
        width: 200,
        fontWeight: 'bold',
        fontSize: 16
    }
}));

function Unauthorized() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    <img src={IMAGES.TTCLogo} width={300} height={300} alt="path"></img>
                </Grid>
                <Grid item xs={12} style={{textAlign:'center', paddingTop: 30}}>
                    <hr></hr>
                    <h3>Unauthorized</h3>
                    <hr></hr>
                    <h4><i>The Ticket Clinic – A Law Firm</i></h4>
                </Grid>
            </Grid>
        </div>
    );
};

export default Unauthorized;