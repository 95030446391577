import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button
} from '@material-ui/core';
import IMAGES from '../utils/images';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        paddingTop: 50,
        paddingLeft: 20,
        paddingRight: 20
    },
    button: {
        color: '#FFEF65',
        backgroundColor: 'black',
        height: 50,
        width: 200,
        fontWeight: 'bold',
        fontSize: 16
    }
}));

function PaymentLanding() {
    const classes = useStyles();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [message, setMessage] = useState('');
    const [expired, setExpired] = useState(params.get('exp'));
    const [email, setEmail] = useState(params.get('emi'));
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        switch(expired){
            case 'NO': 
                setMessage('Payment Successful. A receipt will be emailed to ' + email + '. Thank you for your business.')
                setShowButton(true);
                break;
            case 'YES': 
                setMessage('This payment link has already expired. Please contact your legal Assistant.');
                break;
            case 'PAI':
                setMessage('This Ticket has already been paid.');
                setShowButton(true);
                break;
        }
    }, [expired]);

    return(
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    <img src={IMAGES.TTCLogo} width={300} height={300} alt="path"></img>
                </Grid>
                <Grid item xs={12} style={{textAlign:'center', paddingTop: 30}}>
                    <hr></hr>
                    <h3>{message}</h3>
                    <hr></hr>
                    <h4><i>The Ticket Clinic – A Law Firm</i></h4>
                </Grid>
                <Grid item xs={12} style={{textAlign:'center', paddingTop: 30}}>
                    {showButton && (
                        <Button className={classes.button} onClick={() => {window.open('https://www.theticketclinic.com','_self')}}>Go to Home</Button>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default PaymentLanding;