import { BASE_URL, PAYMENT_BASE_URL } from "../utils/constants";
import axios from "../utils/axios";
import * as Sentry from '@sentry/react';

export const savePayment = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/client/AddPaymentChase`, payload, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    }).catch(function (error){
      Sentry.captureException("Method:savePaymentCallback|Error " + JSON.stringify(error.response.data))
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentInformation = async (barcodeId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/case/GetPayInfoByBarcodeId/${barcodeId}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentInformationByGroupId = async (groupId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/case/GetCaseListInfoByQuoteGroupId/${groupId}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const generateUid = async (payload) => {
  try {
    const response = await axios.post(`${PAYMENT_BASE_URL}/payment/GeneratePaymentUid`, payload, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY_PAYMENT,
        "System": process.env.REACT_APP_TTC_SYSTEM
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};