import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Checkbox,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  Collapse,
  IconButton,
  Box,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { withRouter } from "react-router";
import { getPaymentsCasesList, validProcessedCase } from "../api/client";
import NewQuotePage from "./NewQuotePage";
import { validateOtp } from "../api/otp";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCellDetail = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFEF65",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 8,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    width: 200,
  },
  root: {
    flexGrow: 1,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  button: {
    color: "#FFEF65",
    backgroundColor: "black",
    height: 50,
    width: "50%",
    fontWeight: "bold",
    fontSize: 16,
  },
  inputText: {
    width: "100%",
    height: 30,
    marginTop: 20,
  },
});

class QuoteListPage extends React.Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const modifiedDate = new Date(new Date().getTime() - 5 * 60 * 60 * 1000);
    this.state = {
      userid: params.get("userid"),
      token: params.get("otptoken"),
      open: -1,
      isSelected: false,
      startDate: new Date(modifiedDate).toISOString().substr(0, 10),
      endDate: new Date(modifiedDate).toISOString().substr(0, 10),
      showMessage: false,
      list: [],
      processedAndNotProcessedList: [],
      processedList: [],
      notProcessedList: [],
      openAlert: false,
      messageAlert: "",
      backShow: false,
      showProcessed: false,
      showPage: false,
      regionSelected: params.get("regionid"),
      regions: [],
      regionFilterList: []
    };
    this.setState = this.setState.bind(this);
    this.handleClose = this.handleClose.bind(this);
    //this.handleProcessPayments = this.handleProcessPayments.bind(this);
    this.handleShowProcessed = this.handleShowProcessed.bind(this);
    this.handleRegionFilter = this.handleRegionFilter.bind(this);
    //this.checkRow = this.checkRow.bind(this);
  }

  componentDidMount() {
    this.validateOtp();
  }

  async validateOtp() {
    const response = await validateOtp({
      userName: this.state.userid,
      token: this.state.token,
    });
    if (typeof response == "undefined") {
      this.props.history.push("/unauthorized");
    } else {
      this.setState({ showPage: true });
    }
  }

  handleRegionFilter(event) {
    var filters = event;
    var filtered = [];
    var listToFilter = !this.state.showProcessed
      ? this.state.notProcessedList
      : this.state.processedList;
    switch (Number(filters)) {
      case 0:
        filtered = listToFilter;
        break;
      case 1:
        listToFilter.forEach((a) => {
          if (a.region_id === 1) filtered.push(a);
        });
        break;
      case 2:
        listToFilter.forEach((a) => {
          if (a.region_id === 2) filtered.push(a);
        });
        break;
      default:
        filtered = listToFilter;
        break;
    }
    this.setState({ list: filtered, regionSelected: filters });
  }

  async paymentsList() {
    try {
      this.setState({ backShow: true, showProcessed: false });
      var start = new Date(this.state.startDate);
      var end = new Date(this.state.endDate);
      if (start > end) {
        this.setState({
          openAlert: true,
          messageAlert: "The end date must not be less than the start date",
        });
      } else {
        const response = await getPaymentsCasesList(
          this.state.startDate,
          this.state.endDate
        );
        response.forEach((a) => {
          if (a.is_processed) {
            a.status = "Processed";
          } else {
            a.status = "Pending";
          }
        });
        const listWithProcessed = [];
        const listNotProcessed = [];
        response.forEach((a) => {
          if (a.status === "Pending") listNotProcessed.push(a);
          if (a.status === "Processed") listWithProcessed.push(a);
        });
        this.setState({
          processedAndNotProcessedList: response,
          list: listNotProcessed,
          processedList: listWithProcessed,
          notProcessedList: listNotProcessed,
        });
        const newData = this.state.list.map((item) => ({
          ...item,
          isChecked: false,
        }));
        this.setState({ list: newData });
        this.handleRegionFilter(this.state.regionSelected);
        if (this.state.list.length === 0) this.setState({ showMessage: true });
      }
      this.setState({ backShow: false });
    } catch (error) {
      console.log(error);
    }
  }

  caseDetailPage(barcodeId) {
    window.open(
      process.env.REACT_APP_TTC_TICKET_EXTERNAL_URL + barcodeId,
      "_blank"
    );
  }

  handleClose() {
    this.setState({ openAlert: false });
  }

  async validateProcess(masterId, detailId) {
    const response = await validProcessedCase(masterId, detailId);
    return response;
  }

  handleProcessPayments(id) {
    this.state.list.forEach(async (element) => {
      if (element.tickets.some(e => e.citation_infraction_type_id === 0)){
        this.setState({ openAlert: true, messageAlert: "Missing price for Statute: " +  element.tickets[0].citation_statute_code + ", County: " + element.county + ", Type Description: " + element.tickets[0].citation_type_description})
      } else {
        if (element.master_id === id) {
          const validation = await this.validateProcess(element.id, element.tickets[0].id);
          if (!validation) {
            localStorage.setItem("payment", JSON.stringify(element));
            this.props.history.push("/new-quote?userid=" + this.state.userid);
          } else {
            this.setState({ openAlert: true, messageAlert: "This case is already processed" })
          }
        }
      }
    });
  }

  handleShowProcessed() {
    if (this.state.showProcessed) {
      this.setState({
        list: this.state.notProcessedList,
        regionSelected: "0",
      });
    } else {
      this.setState({
        list: this.state.processedList,
        regionSelected: "0",
      });
    }
  }

  handleGoAnotherPage() {
    return <NewQuotePage />;
  }

  getCurrentDate() {
    let date = new Date();
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0");
    let yyyy = String(date.getFullYear());
    return yyyy + "-" + mm + "-" + dd;
  }

  render() {
    const re = /(\d{3})(\d{3})(\d{4})/;
    return (
      <div>
        {this.state.showPage ? (
          <div
            style={{
              flexGrow: 1,
              paddingTop: 20,
              paddingLeft: 50,
              paddingRight: 50,
              paddingBottom: 50,
            }}
          >
            <Grid container spacing={3}>
              <Grid item md={3} lg={3} xs={12}>
                <TextField
                  id="date"
                  label="Start Date"
                  type="date"
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: 30,
                    marginTop: 20,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.startDate}
                  format="MM-DD-YYYY"
                  onChange={(e) => this.setState({ startDate: e.target.value })}
                />
              </Grid>
              <Grid item md={3} lg={3} xs={12}>
                <TextField
                  id="dateEnd"
                  label="End Date"
                  type="date"
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: 30,
                    marginTop: 20,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  format="MM-DD-YYYY"
                  value={this.state.endDate}
                  onChange={(e) => this.setState({ endDate: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  select
                  label="Region"
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    width: "100%",
                    marginTop: 20,
                  }}
                  variant="outlined"
                  value={this.state.regionSelected}
                  onChange={(e) => {
                    this.setState({ regionSelected: e.target.value });
                    this.handleRegionFilter(e.target.value);
                  }}
                >
                  <option key="0" value="0">
                    All
                  </option>
                  <option key="1" value="1">
                    North FL
                  </option>
                  <option key="2" value="2">
                    South FL
                  </option>
                </TextField>
              </Grid>
              <Grid item md={3} lg={3} xs={12}>
                <Grid item md={12} lg={12} xs={12}>
                  <Button
                    variant="contained"
                    style={{
                      marginTop: 20,
                      color: "#FFEF65",
                      height: 55,
                      backgroundColor: "black",
                      width: "50%",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                    onClick={() => this.paymentsList()}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item md={12} lg={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.showProcessed}
                        onChange={() => {
                          this.setState({
                            showProcessed: !this.state.showProcessed,
                          });
                          this.handleShowProcessed();
                        }}
                        name="processed"
                      />
                    }
                    label="Show processed cases"
                  />
                </Grid>
              </Grid>
              {this.state.list.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <TableContainer style={{ paddingTop: 20 }}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell style={{ width: "5%" }} />
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "5%" }}
                            >
                              Id
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "20%" }}
                            >
                              Citation Number
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "10%" }}
                            >
                              County
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "15%" }}
                            >
                              Client
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "15%" }}
                            >
                              Mobile Number
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "5%" }}
                            >
                              State
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "5%" }}
                            >
                              Paid?
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "10%" }}
                            >
                              Citation Date
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "10%" }}
                            >
                              Register Date
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "10%" }}
                            >
                              Origin
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold", width: "5%" }}
                            >
                              Status
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "5%" }} />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.list.map((row, index) => (
                            <>
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {row.tickets.length !== 0 && (
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      onClick={() =>
                                        this.setState({
                                          open:
                                            this.state.open === index
                                              ? -1
                                              : index,
                                        })
                                      }
                                    >
                                      {this.state.open ? (
                                        <KeyboardArrowUp />
                                      ) : (
                                        <KeyboardArrowDown />
                                      )}
                                    </IconButton>
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>{row.id}</StyledTableCell>
                                <StyledTableCell>
                                  {row.barcode_id != null ? (
                                    <label
                                      onClick={() =>
                                        this.caseDetailPage(row.barcode_id)
                                      }
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {row.tickets
                                        ?.map((u) => u.citations_number)
                                        .join(", ")}
                                    </label>
                                  ) : (
                                    <label>
                                      {row.tickets
                                        ?.map((u) => u.citations_number)
                                        .join(", ")}
                                    </label>
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>{row.county}</StyledTableCell>
                                <StyledTableCell>
                                  {row.first_name + " " + row.last_name}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.cell_phone.replace(
                                    re,
                                    (_, a, b, c) => `(${a}) ${b}-${c}`
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>{row.state}</StyledTableCell>
                                <StyledTableCell>
                                  {row.paid === "1" ? "YES" : "NO"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.tickets[0]?.citations_date.slice(0, 10)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.createdAt.slice(0, 10)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.origin[0].toUpperCase() +
                                    row.origin.substring(1)}
                                </StyledTableCell>
                                <StyledTableCell>{row.status}</StyledTableCell>
                                <StyledTableCell padding="checkbox">
                                  {row.status === "Pending" && (
                                    <Button
                                      variant="contained"
                                      style={{
                                        color: "#FFEF65",
                                        backgroundColor: "black",
                                        width: "50%",
                                        fontWeight: "bold",
                                        fontSize: 9,
                                      }}
                                      onClick={() =>
                                        this.handleProcessPayments(
                                          row.master_id
                                        )
                                      }
                                    >
                                      Process
                                    </Button>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={13}
                                >
                                  <Collapse
                                    in={this.state.open === index}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box
                                      style={{
                                        paddingTop: 5,
                                        justifyContent: "center",
                                        paddingLeft: 100,
                                        paddingRight: 100,
                                        paddingBottom: 20,
                                      }}
                                    >
                                      <h3 style={{ fontWeight: "bold" }}>
                                        Citations
                                      </h3>
                                      <Table>
                                        <TableHead>
                                          <StyledTableRow>
                                            <StyledTableCellDetail
                                              align="center"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              #
                                            </StyledTableCellDetail>
                                            <StyledTableCellDetail
                                              align="center"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Citation Number
                                            </StyledTableCellDetail>
                                            <StyledTableCellDetail
                                              align="center"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Citation Fee
                                            </StyledTableCellDetail>
                                            <StyledTableCellDetail
                                              align="center"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Ticket
                                            </StyledTableCellDetail>
                                          </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.tickets.map((r) => (
                                            <>
                                              <StyledTableRow>
                                                <StyledTableCell align="center">
                                                  {r.id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                  {r.citations_number}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                  ${" "}
                                                  {Number(
                                                    r.citations_fee
                                                  ).toFixed(2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                  {r.citations_attachment_url !==
                                                    "" && (
                                                    <Button
                                                      variant="contained"
                                                      style={{
                                                        color: "#FFEF65",
                                                        backgroundColor:
                                                          "black",
                                                        width: 150,
                                                        fontWeight: "bold",
                                                        fontSize: 12,
                                                      }}
                                                      onClick={() => {
                                                        window.open(
                                                          r.citations_attachment_url,
                                                          "_blank"
                                                        );
                                                      }}
                                                    >
                                                      View
                                                    </Button>
                                                  )}
                                                </StyledTableCell>
                                              </StyledTableRow>
                                            </>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </StyledTableCell>
                              </StyledTableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  {this.state.showMessage && (
                    <h1>There is no match data with the search filter</h1>
                  )}
                </Grid>
              )}
            </Grid>
            <Dialog
              open={this.state.openAlert}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
              maxWidth={"xs"}
            >
              <DialogTitle
                id="responsive-dialog-title"
                style={{
                  backgroundColor: "#FFEF65",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {this.state.messageAlert}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{
                    color: "#FFEF65",
                    backgroundColor: "black",
                    height: 50,
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Backdrop
              style={{ position: "absolute", zIndex: 1 }}
              open={this.state.backShow}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <h1>Loading...</h1>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(QuoteListPage));
