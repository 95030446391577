import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        paddingTop: 300,
        paddingLeft: 20,
        paddingRight: 20
    },
    button: {
        color: '#FFEF65',
        backgroundColor: 'black',
        height: 50,
        width: '50%',
        fontWeight: 'bold',
        fontSize: 16
    }
}));

function HomePage() {
    const classes = useStyles();
    const history = useHistory();
    const [showPage, setShowPage] = useState(process.env.REACT_APP_TTC_PERMISION_VIEW)

    useEffect(() => {
        if (showPage == "false") {
          history.push("/unauthorized");
        }
      }, [history, showPage]);

    return(
        <div className={classes.root}>
        </div>
    );
};

export default HomePage;